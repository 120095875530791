import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import CustomPagination from '../../../../../Common/CustomPagination';
import SimpleLinearProgress from '../../../../../Common/SimpleLinearProgress';
import { formatDateWithHour, numberToRealWithPeriod } from '../../../../../../helpers/formatData';
import OrderCompanyLogo from '../../../../../Orders/elements/OrderCompanyLogo';
import InvoiceInquiryLink from '../../../../../Common/InvoiceInquiryLink';
import OrderStatusTypography from '../../../../../Orders/elements/OrderStatusTypography';
import LimitTypography from '../../../../../Common/LimitTypography';

const useStyles = makeStyles(() => ({
  paperTable: {
    height: '75vh',
  },
  tableCellPointer: {
    cursor: 'pointer',
  },
}));

function MovementTable({ handleSelectOrder, movements, limit, page, setPage }) {
  const classes = useStyles();

  const getLabelColorType = (sourceType) => {
    if (sourceType === 'Compra') {
      return '#45046a';
    }
    if (sourceType === 'Reserva') {
      return '#ffb000';
    }
    return '#04D9B2';
  };

  return (
    <Paper className={classes.paperTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Pedido</TableCell>
            <TableCell align="center">Empresa</TableCell>
            <TableCell align="center" width={60}>
              NF-e
            </TableCell>
            <TableCell align="center" width={150}>
              Movimento
            </TableCell>
            <TableCell align="center">Data</TableCell>
            <TableCell align="center" width={100}>
              Quantidade
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {movements.stockMovements.map((stockMovement) => (
            <TableRow key={stockMovement._id}>
              <TableCell
                align="center"
                onClick={() => handleSelectOrder(stockMovement)}
                className={classes.tableCellPointer}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Grid item>
                      <Typography>{stockMovement.sourceId}</Typography>
                    </Grid>
                    <Typography color={getLabelColorType(stockMovement.sourceType)}>
                      {stockMovement.sourceType}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <OrderCompanyLogo orderCompany={stockMovement.company} />
              </TableCell>
              <TableCell align="center">
                <InvoiceInquiryLink
                  invoice={{ key: stockMovement.invoiceKey, number: stockMovement.invoiceNumber }}
                  showInvoiceNumber
                />
              </TableCell>
              <TableCell align="center">
                <Grid container direction="column">
                  <Grid item>
                    <LimitTypography text={stockMovement.source} maxLength={16} />
                  </Grid>
                  <Grid item>
                    <OrderStatusTypography orderStatus={stockMovement.description} maxLength={16} />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Typography>{formatDateWithHour(stockMovement.date)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Grid container direction="column">
                  <Grid item>
                    <Typography>{stockMovement.quantity} x</Typography>
                  </Grid>
                  <Grid item>R$ {numberToRealWithPeriod(stockMovement.selectedPrice)}</Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
          {movements.stockMovements.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <SimpleLinearProgress loading={movements.loading} />
                {!movements.loading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Typography>Nenhuma movimentação para exibir</Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <CustomPagination
                page={page}
                total={movements.countMovements}
                limit={limit}
                handleChangePage={(_, value) => {
                  setPage(value);
                }}
                showInput={false}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default MovementTable;
