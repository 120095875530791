import React, { memo } from 'react';
import { Typography } from '@mui/material';
import orderStatusColor from '../../../../constant/orderStatusColor';
import { skipFirstWord } from '../../../../helpers/formatData';
import LimitTypography from '../../../Common/LimitTypography';

function OrderStatusTypography({ orderStatus, onlyStatusName, maxLength }) {
  let status = orderStatus;

  if (onlyStatusName) {
    status = skipFirstWord(orderStatus);
  }

  if (maxLength) {
    return (
      <LimitTypography color={orderStatusColor(orderStatus)} text={status} maxLength={maxLength} />
    );
  }

  return <Typography color={orderStatusColor(orderStatus)}>{status}</Typography>;
}

export default memo(OrderStatusTypography);
