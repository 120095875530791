import { Alert, AlertTitle, Box, Grid, Paper, Snackbar, Typography } from '@mui/material';
import Axios from 'axios';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatHandleError } from '../../../../../helpers/formatData';
import SimpleImage from '../../../../Common/SimpleImage';
import LimitTypography from '../../../../Common/LimitTypography';
import MovementTable from './MovementTable';
import SimpleLinearProgress from '../../../../Common/SimpleLinearProgress';
import OrderDetails from '../../../../Orders/Details';
import ProductEntry from '../../../../ProductEntries/Details';

const useStyles = makeStyles((theme) => ({
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
  productPaper: {
    height: 85,
    padding: 5,
    backgroundColor: theme.palette.primary.primary,
  },
}));

function DialogComponent({ openDialogMovement, product, handleCloseDialogMovement }) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [entryLimit] = useState(10);
  const [entryPage, setEntryPage] = useState(1);

  const [entryMovements, setEntryMovements] = useState({
    stockMovements: [],
    productQuantity: 0,
    countMovements: 0,
    loading: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setEntryMovements((oldFields) => ({ ...oldFields, loading: true }));

        const response = await Axios.get(`/stock-movement/product/${product.productId}`, {
          params: {
            type: 'Entrada',
            limit: entryLimit,
            skip: entryPage * entryLimit - entryLimit,
            sortBy: 'date',
            sortDirection: 'desc',
          },
        });

        setEntryMovements((oldFields) => ({ ...oldFields, ...response.data, loading: false }));
      } catch (error) {
        setEntryMovements((oldFields) => ({ ...oldFields, loading: false }));
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as movimentações de entradas',
          error,
        });
      }
    };
    fetchData();
  }, [entryPage, entryLimit]);

  const [outputLimit] = useState(10);
  const [outputPage, setOutputPage] = useState(1);

  const [outputMovements, setOutputMovements] = useState({
    stockMovements: [],
    productQuantity: 0,
    countMovements: 0,
    loading: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOutputMovements((oldFields) => ({ ...oldFields, loading: true }));

        const response = await Axios.get(`/stock-movement/product/${product.productId}`, {
          params: {
            type: 'Saída',
            limit: outputLimit,
            skip: outputPage * outputLimit - outputLimit,
            sortBy: 'date',
            sortDirection: 'desc',
          },
        });

        setOutputMovements((oldFields) => ({ ...oldFields, ...response.data, loading: false }));
      } catch (error) {
        setOutputMovements((oldFields) => ({ ...oldFields, loading: false }));
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as movimentações de saídas',
          error,
        });
      }
    };
    fetchData();
  }, [outputPage, outputLimit]);

  const [loadingOrder, setLoadingOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedEntryOrder, setSelectedEntryOrder] = useState(null);

  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const closeOrderDetails = () => setOpenOrderDetails(false);

  const [openEntryOrderDetails, setOpenEntryOrderDetails] = useState(false);
  const closeEntryOrderDetails = () => setOpenEntryOrderDetails(false);

  const handleSelectOrder = async (movement) => {
    if (movement && movement.sourceId) {
      try {
        setLoadingOrder(true);

        const response = await Axios.get(
          `/${movement.sourceType === 'Compra' ? 'entries' : 'orders'}/${movement.sourceId}`,
        );

        setLoadingOrder(false);
        const orderDetails = response.data.order || response.data.entryOrder || {};

        if (orderDetails.orderId) {
          setSelectedOrder(orderDetails);
          setOpenOrderDetails(true);
        } else if (orderDetails.entryOrderId) {
          setSelectedEntryOrder(orderDetails);
          setOpenEntryOrderDetails(true);
        }
      } catch (error) {
        setLoadingOrder(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar o pedido',
          error,
        });
      }
    }
  };

  const [stockByMovement, setStockByMovement] = useState(0);

  useEffect(() => {
    setStockByMovement(entryMovements.productQuantity - outputMovements.productQuantity);
  }, [entryMovements.productQuantity, outputMovements.productQuantity]);

  const loading = entryMovements.loading || outputMovements.loading || loadingOrder;

  return (
    <SimpleDialog
      content={
        <Box width={1100}>
          <SimpleLinearProgress loading={loading} fixedPosition />
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={2} className={classes.productPaper}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box maxWidth="75px" maxHeight="75px">
                          {product.images.length > 0 ? (
                            <SimpleImage
                              src={product.images[0].src}
                              height={75}
                              width={75}
                              alt={product.name}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <LimitTypography
                              style={{ color: 'grey' }}
                              text={product.name}
                              maxLength={60}
                              variant="caption"
                            />
                          </Grid>
                          <Grid item>
                            <LimitTypography
                              text={product.technicalName}
                              maxLength={60}
                              variant="caption"
                            />
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: 'grey' }} variant="caption">
                              Estoque: {product.stock} / Reservados: {product.reservedStock} /
                              Disponível: {product.stock - product.reservedStock}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!loading ? (
                    <Grid item>
                      <Alert
                        severity={
                          product.stock - product.reservedStock !== stockByMovement
                            ? 'warning'
                            : 'success'
                        }
                      >
                        <AlertTitle>
                          Disponível: {product.stock - product.reservedStock} / Movimentado:{' '}
                          {stockByMovement}
                        </AlertTitle>
                        {product.stock - product.reservedStock !== stockByMovement
                          ? 'Estoque não confere com as movimentações'
                          : 'Estoque confere com as movimentações'}
                      </Alert>
                    </Grid>
                  ) : null}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h5">Entradas</Typography>
                    </Grid>
                    <Grid item>
                      <MovementTable
                        handleSelectOrder={handleSelectOrder}
                        movements={entryMovements}
                        limit={entryLimit}
                        page={entryPage}
                        setPage={setEntryPage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h5">Saídas</Typography>
                    </Grid>
                    <Grid item>
                      <MovementTable
                        handleSelectOrder={handleSelectOrder}
                        movements={outputMovements}
                        limit={outputLimit}
                        page={outputPage}
                        setPage={setOutputPage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                    {snackbar.message}
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Grid>
          {openOrderDetails && selectedOrder?.orderId && (
            <OrderDetails
              openOrderDetails={openOrderDetails}
              closeOrderDetails={closeOrderDetails}
              selectedOrder={selectedOrder}
            />
          )}
          {openEntryOrderDetails && selectedEntryOrder?.entryOrderId && (
            <ProductEntry
              openProductEntry={openEntryOrderDetails}
              handleCloseProductEntry={closeEntryOrderDetails}
              selectedEntryOrder={selectedEntryOrder}
            />
          )}
        </Box>
      }
      dialogTitle="Movimentação"
      handleClose={handleCloseDialogMovement}
      openDialog={openDialogMovement}
    />
  );
}

export default DialogComponent;
