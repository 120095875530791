const orderStatusColor = (orderStatus) => {
  let color = '#ffa300';

  switch (orderStatus) {
    case 'Pedido criado':
      color = '#bbb';
      break;
    case 'Pedido confirmado':
      color = '#ffa300';
      break;
    case 'Pedido faturado':
      color = '#ffa300';
      break;
    case 'Pedido embalado':
      color = '#ffa300';
      break;
    case 'Pedido concluído':
      color = '#00c600';
      break;
    case 'Pedido enviado':
      color = '#00c600';
      break;
    case 'Pedido entregue':
      color = '#00c600';
      break;
    case 'Pedido não entregue':
      color = '#a17917';
      break;
    case 'Pedido devolvido':
      color = '#0d00fc';
      break;
    case 'Pedido cancelado':
      color = '#ff2222';
      break;
    default:
      color = '#bbb';
      break;
  }

  return color;
};

export default orderStatusColor;
