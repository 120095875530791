/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  Snackbar,
  Tooltip,
  Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CropFreeIcon from '@mui/icons-material/CropFree';
import GetAppIcon from '@mui/icons-material/GetApp';
import HttpIcon from '@mui/icons-material/Http';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import HeightIcon from '@mui/icons-material/Height';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Axios from 'axios';
import CompositionTable from './CompositionTable';
import CustomInput from '../../../CustomInput';
import ProductsModal from '../../TableModal';
import SimpleDialog from '../../../Common/SimpleDialog';
import DialogMovement from './DialogMovement';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import DialogPrintProductStamp from './DialogPrintProductStamp';
import CategoryProduct from './CategoryProduct';
import TableSuppliers from './TableSuppliers';
import PreviewVideoForProduct from './PreviewVideoForProduct';
import { isEAN } from '../../../../services/melhorGestao/validator';
import { formatHandleError, formatWeight } from '../../../../helpers/formatData';
import getAutoBalancesWeight from '../../../../services/melhorGestao/weighingBalances';
import DialogAttributes from './DialogAttributes';
import SimpleImage from '../../../Common/SimpleImage';
import TagsAutocomplete from '../../../Common/TagsAutocomplete';
import RelatedProductsModal from './RelatedProductsModal';

const useStyles = makeStyles((theme) => ({
  inputAdornmentWidth: {
    transform: 'rotateZ(90deg)',
  },
  inputAdornmentLength: {
    transform: 'rotateZ(45deg)',
  },
  minGridSize: {
    minWidth: 600,
  },
  smallTypography: {
    fontSize: '0.85rem',
  },
  boxImage: {
    cursor: 'pointer',
  },
  images: {
    maxHeight: 223,
    maxWidth: 223,
    height: 'auto',
    margin: 7,
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
  },
  buttonAddImage: {
    width: 140,
    top: -17,
    left: -40,
    cursor: 'pointer',
  },
  typographyAddImage: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

function InformationTab({ productForm, handleChange, setProductForm, formChanged }) {
  const classes = useStyles();

  const [openProductsModal, setOpenProductsModal] = useState(false);
  const handleOpenProductsModal = useCallback(() => setOpenProductsModal(true), []);
  const handleCloseProductsModal = useCallback(() => setOpenProductsModal(false), []);

  const [openDialogDescription, setOpenDialogDescription] = useState(false);
  const handleOpenDialogDescription = useCallback(() => setOpenDialogDescription(true), []);
  const handleCloseDialogDescription = useCallback(() => setOpenDialogDescription(false), []);

  const [openDialogVideoProduct, setOpenDialogVideoProduct] = useState(false);
  const handleOpenDialogVideoProduct = useCallback(() => setOpenDialogVideoProduct(true), []);
  const handleCloseDialogVideoProduct = useCallback(() => setOpenDialogVideoProduct(false), []);

  const [openDialogSupplier, setOpenDialogSupplier] = useState(false);
  const handleOpenDialogSupplier = useCallback(() => setOpenDialogSupplier(true), []);
  const handleCloseDialogSupplier = useCallback(() => setOpenDialogSupplier(false), []);

  const [openDialogMovement, setOpenDialogMovement] = useState(false);
  const handleOpenDialogMovement = useCallback(() => setOpenDialogMovement(true), []);
  const handleCloseDialogMovement = useCallback(() => setOpenDialogMovement(false), []);

  const [openDialogPrintProductStamp, setOpenDialogPrintProductStamp] = useState(false);
  const handleOpenDialogPrintProductStamp = () => setOpenDialogPrintProductStamp(true);
  const handleCloseDialogPrintProductStamp = () => setOpenDialogPrintProductStamp(false);

  const [openDialogAttributes, setOpenDialogAttributes] = useState(false);
  const handleOpenDialogAttributes = () => setOpenDialogAttributes(true);
  const handleCloseDialogAttributes = () => setOpenDialogAttributes(false);

  const [openDialogRelatedProducts, setOpenDialogRelatedProducts] = useState(false);
  const handleOpenDialogRelatedProducts = () => setOpenDialogRelatedProducts(true);
  const handleCloseDialogRelatedProducts = () => setOpenDialogRelatedProducts(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleChangeDescription = useCallback(
    (event) => {
      const fieldValue = event.target.value;

      setProductForm((oldFields) => ({
        ...oldFields,
        description: fieldValue,
        reviews: {
          ...oldFields.reviews,
          description: fieldValue.length < 200,
        },
      }));
    },
    [setProductForm],
  );

  const handleChangePackage = useCallback(
    (event) => {
      const fieldValue = event.target.value;
      const fieldName = event.target.name;

      setProductForm((oldFields) => ({
        ...oldFields,
        package: {
          ...oldFields.package,
          [fieldName]: parseFloat(fieldValue) || 0,
        },
        reviews: {
          ...oldFields.reviews,
          [fieldName]: !parseFloat(fieldValue),
        },
      }));
    },
    [setProductForm],
  );

  const [loadingBalanceWeight, setLoadingBalanceWeight] = useState(false);
  const handleBalanceGetWeight = async () => {
    setLoadingBalanceWeight(true);
    const weight = await getAutoBalancesWeight({ setSnackbar });

    if (weight !== '00000') {
      setProductForm((oldFields) => ({
        ...oldFields,
        package: {
          ...oldFields.package,
          weight: formatWeight(weight),
        },
        reviews: {
          ...oldFields.reviews,
          weight: false,
        },
      }));
    }
    setLoadingBalanceWeight(false);
  };

  const handleChangeTags = useCallback(
    (_, value) => {
      setProductForm((oldFields) => ({
        ...oldFields,
        tags: value,
      }));
    },
    [setProductForm],
  );

  const handleChangeProductInputs = useCallback(
    ({ selectedProducts }) => {
      setProductForm((oldFields) => ({
        ...oldFields,
        productInputs: selectedProducts.map((selectedProduct) => ({
          productId: selectedProduct.productId,
          quantity: selectedProduct.quantity,
        })),
      }));
    },
    [setProductForm],
  );

  const handleChangeRelatedProducts = (relatedProducts) => {
    setProductForm((oldFields) => ({
      ...oldFields,
      relatedProducts: relatedProducts.filter((prod) => prod !== productForm.productId),
    }));
  };

  const handleForSale = () => {
    setProductForm((oldFields) => ({
      ...oldFields,
      active: {
        ...oldFields.active,
        forSale: !productForm.active.forSale,
      },
    }));
  };

  const [openDialogImages, setOpenDialogImages] = useState(false);
  const handleOpenDialogImages = useCallback(() => setOpenDialogImages(true), []);
  const handleCloseDialogImages = useCallback(() => setOpenDialogImages(false), []);

  const [loadingUploadImage, setLoadingUploadImage] = useState(false);

  const handleUploadImage = async (event) => {
    try {
      if (productForm.productId && event.target.files) {
        let orderPrefix = productForm.images.length + 1;
        setLoadingUploadImage(true);

        await Array.from(event.target.files).reduce(async (promise, file) => {
          await promise;

          if (file) {
            const formData = new FormData();
            formData.append('image', file);

            await Axios.post(`${process.env.REACT_APP_REST_URL_IMG}/imagem/upload`, formData, {
              params: {
                sku: productForm.productId,
                orderPrefix,
                name: productForm.name,
              },
              headers: {
                'token-mg-admin': process.env.REACT_APP_API_TOKEN_ADMIN,
                'content-type': 'multipart/form-data',
              },
            });

            const response = await Axios.post(`/catalog/product/${productForm.productId}/images`);
            const { updatedProduct } = response.data;

            setProductForm((oldFields) => ({
              ...oldFields,
              images: updatedProduct.images,
              recentlyUpdatedImages: true,
            }));

            orderPrefix += 1;
          }
        }, Promise.resolve());

        return setLoadingUploadImage(false);
      }
      handleCloseDialogImages();

      return setSnackbar({
        message: 'Para enviar imagens complete o produto e salve-o primeiro',
        open: true,
        type: 'error',
      });
    } catch (error) {
      setLoadingUploadImage(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao enviar a imagem',
        error,
      });
    }
  };

  const changeImagePosition = async (index, to) => {
    const sourcePosition = index + 1;
    let destPosition = 0;

    if (to === 'left') {
      destPosition = sourcePosition - 1;
    }

    if (to === 'right') {
      destPosition = sourcePosition + 1;
    }

    if (
      sourcePosition >= 1 &&
      sourcePosition <= productForm.images.length &&
      destPosition >= 1 &&
      destPosition <= productForm.images.length
    ) {
      try {
        const response = await Axios.put(
          `/catalog/product/${productForm.productId}/images/position`,
          {
            sourcePosition,
            destPosition,
            productName: productForm.name,
          },
        );

        const { updatedProduct } = response.data;

        setProductForm((oldFields) => ({
          ...oldFields,
          images: updatedProduct.images,
          recentlyUpdatedImages: true,
        }));
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao mudar a posição da imagem',
          error,
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [openDialogConfirmDeleteImage, setOpenDialogConfirmDeleteImage] = useState(false);
  const [imageToDelete, setImageToDelete] = useState();

  const handleOpenDialogConfirmDeleteImage = useCallback(
    () => setOpenDialogConfirmDeleteImage(true),
    [],
  );
  const handleCloseDialogConfirmDeleteImage = useCallback(
    () => setOpenDialogConfirmDeleteImage(false),
    [],
  );

  const handleDeleteImage = async (image) => {
    setImageToDelete(image);
    handleOpenDialogConfirmDeleteImage();
  };

  const handleConfirmDeleteImage = async () => {
    try {
      const orderPrefix = productForm.images.indexOf(imageToDelete) + 1;
      setLoadingUploadImage(true);

      await Axios.delete(`${process.env.REACT_APP_REST_URL_IMG}/imagem/delete`, {
        params: {
          sku: productForm.productId,
          orderPrefix,
          name: productForm.name,
        },
        headers: {
          'token-mg-admin': process.env.REACT_APP_API_TOKEN_ADMIN,
        },
      });

      const response = await Axios.delete(
        `/catalog/product/${productForm.productId}/images/${imageToDelete._id}`,
      );

      const { updatedProduct } = response.data;

      setProductForm((oldFields) => ({
        ...oldFields,
        images: updatedProduct.images,
        recentlyUpdatedImages: true,
      }));

      setLoadingUploadImage(false);
      setImageToDelete();
      return handleCloseDialogConfirmDeleteImage();
    } catch (error) {
      setLoadingUploadImage(false);
      setImageToDelete();
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu tentar deletar a imagem',
        error,
      });
    }
  };

  const [openDialogCategory, setOpenDialogCategory] = useState(false);
  const handleOpenDialogCategory = useCallback(() => setOpenDialogCategory(true), []);
  const handleCloseDialogCategory = useCallback(() => setOpenDialogCategory(false), []);

  const [validEan, setValidEan] = useState(true);
  const [timeoutValidatorEanId, setTimeoutValidatorEanId] = useState(false);
  const [loadingValidatorEan, setLoadingValidatorEan] = useState(false);

  useEffect(() => {
    if (formChanged) {
      setLoadingValidatorEan(true);
      if (timeoutValidatorEanId) {
        clearTimeout(timeoutValidatorEanId);
      }
      setTimeoutValidatorEanId(
        setTimeout(async () => {
          const resultEAN = await isEAN(productForm.othersIds.gtin);
          setValidEan(resultEAN);

          setLoadingValidatorEan(false);
        }, 1000),
      );
    }
  }, [productForm.othersIds.gtin]);

  const [validUrl, setValidUrl] = useState(true);
  const [loadingValidatorUrl, setLoadingValidatorUrl] = useState(false);

  return (
    <Grid container direction="column" spacing={3}>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Informações</Typography>
            {productForm.categories && productForm.categories[0]
              ? productForm.categories.map((category, index) => {
                  if (index === 0) {
                    return category.name;
                  }
                  return ` > ${category.name}`;
                })
              : 'Nenhuma categoria foi selecionada'}
          </Grid>
          <Grid item onClick={handleOpenDialogImages}>
            <Box
              bgcolor="#fff"
              width="70px"
              height="70px"
              position="absolute"
              right="20px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
              className={classes.boxImage}
            >
              {productForm.images && productForm.images.length > 0 ? (
                <SimpleImage
                  src={productForm.images[0].src}
                  height={70}
                  width={70}
                  imgWidth={70}
                  imgHeight={70}
                  alt={productForm.name}
                />
              ) : (
                <Typography variant="caption">Imagens</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CustomInput name="name" label="Nome" value={productForm.name} onChange={handleChange} />
      </Grid>
      <Grid item>
        <Box position="relative">
          <CustomInput
            name="technicalName"
            label="Nome técnico"
            value={productForm.technicalName}
            onChange={handleChange}
          />
          {productForm.productId ? (
            <Box position="absolute" right="0px" top="6px">
              <Chip label="Etiqueta" onClick={handleOpenDialogPrintProductStamp} />
            </Box>
          ) : null}
          {openDialogPrintProductStamp ? (
            <DialogPrintProductStamp
              handleCloseDialog={handleCloseDialogPrintProductStamp}
              openDialog={openDialogPrintProductStamp}
              product={productForm}
              setSnackbar={setSnackbar}
            />
          ) : null}
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              name="localization"
              value={productForm.localization || ''}
              label="Localização do Estoque"
              onChange={handleChange}
              InputAdornmentIcon={EditLocationIcon}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomInput
              disabled
              value={productForm.slug}
              label="URL"
              InputAdornmentIcon={HttpIcon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              name="gtin"
              label="Código de Barras"
              value={productForm.othersIds.gtin}
              error={!validEan}
              onChange={handleChange}
              InputAdornmentIcon={CropFreeIcon}
              InputEndAdornment={
                productForm.othersIds.gtin ? (
                  <Tooltip
                    placement="right"
                    title={<Typography align="center">Baixar imagem do código</Typography>}
                  >
                    <IconButton
                      size="small"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_REST_URL_IMG}/imagens/barcode/${productForm.othersIds.gtin}?text=${productForm.technicalName}&dpi=500`,
                        )
                      }
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                ) : null
              }
              loading={loadingValidatorEan}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              name="brand"
              label="Marca"
              value={productForm.brand}
              onChange={handleChange}
              InputAdornmentIcon={CopyrightIcon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="height"
              label="Altura (cm)"
              value={productForm.package.height || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={HeightIcon}
              error={productForm.reviews.height}
              number
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="width"
              label="Largura (cm)"
              value={productForm.package.width || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={HeightIcon}
              InputAdornmentStyle={classes.inputAdornmentWidth}
              error={productForm.reviews.width}
              number
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="length"
              label="Comprimento (cm)"
              value={productForm.package.length || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={HeightIcon}
              InputAdornmentStyle={classes.inputAdornmentLength}
              error={productForm.reviews.length}
              number
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomInput
              name="weight"
              label="Peso (kg)"
              value={productForm.package.weight || ''}
              onChange={handleChangePackage}
              InputAdornmentIcon={FitnessCenterIcon}
              loading={loadingBalanceWeight}
              InputEndAdornment={
                <Tooltip
                  placement="right"
                  title={<Typography align="center">Buscar peso da balança</Typography>}
                >
                  <IconButton size="small" onClick={handleBalanceGetWeight}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              }
              error={productForm.reviews.weight}
              number
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TagsAutocomplete
              type="products"
              handleOnChange={handleChangeTags}
              selectedValue={productForm.tags}
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CustomInput
          name="sellerObs"
          label="Observações para o vendedor"
          value={productForm.sellerObs || ''}
          onChange={handleChange}
          variant="outlined"
          rows={2}
        />
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogCategory}>
              Categoria
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogVideoProduct}>
              Vídeo
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogAttributes}>
              Características
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogDescription}>
              Descrição
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogMovement}>
              Movimentação
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogSupplier}>
              Fornecedor
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogRelatedProducts}>
              Produtos Relacionados
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={productForm.active.forSale || false}
                  onChange={handleForSale}
                  checked={productForm.active.forSale || false}
                  name="forSale"
                  color="primary"
                />
              }
              label={
                <Box className={classes.smallTypography}>
                  Para venda
                  <br />
                  {productForm.active.forSale ? 'Sim' : 'Não'}
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={productForm.composition}
                  onChange={handleChange}
                  checked={productForm.composition}
                  name="composition"
                  color="primary"
                />
              }
              label={
                <Box className={classes.smallTypography}>
                  Produto
                  <br />
                  {productForm.composition ? 'Composto' : 'Normal'}
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!productForm.composition}
              onClick={handleOpenProductsModal}
            >
              Escolher composição
            </Button>
          </Grid>
          {openProductsModal && (
            <ProductsModal
              openProductsModal={openProductsModal}
              handleCloseProductsModal={handleCloseProductsModal}
              handleConfirmSelect={handleChangeProductInputs}
              alreadySelectedProducts={productForm.productInputs}
            />
          )}
        </Grid>
      </Grid>
      <Grid item>{productForm.composition && <CompositionTable productForm={productForm} />}</Grid>
      {openDialogMovement && (
        <DialogMovement
          openDialogMovement={openDialogMovement}
          product={productForm}
          handleCloseDialogMovement={handleCloseDialogMovement}
        />
      )}

      {openDialogCategory && (
        <CategoryProduct
          openDialogCategory={openDialogCategory}
          handleCloseDialogCategory={handleCloseDialogCategory}
          setProductForm={setProductForm}
          productCategories={productForm.categories}
        />
      )}

      {openDialogAttributes && (
        <DialogAttributes
          openDialogAttributes={openDialogAttributes}
          handleCloseDialogAttributes={handleCloseDialogAttributes}
          productForm={productForm}
          setProductForm={setProductForm}
        />
      )}

      {openDialogDescription && (
        <SimpleDialog
          content={
            <Grid className={classes.minGridSize}>
              <CustomInput
                name="description"
                label="Descrição"
                rows={20}
                value={productForm.description || ''}
                onChange={handleChangeDescription}
                helperText={
                  productForm.reviews.description
                    ? `Digite no mínimo 200 caracteres (${productForm.description.length})`
                    : productForm.description.length
                }
                error={productForm.reviews.description}
              />
            </Grid>
          }
          dialogTitle="Descrição em texto"
          dialogText="Altere abaixo a descrição do produto"
          handleClose={handleCloseDialogDescription}
          openDialog={openDialogDescription}
        />
      )}

      {openDialogVideoProduct && (
        <SimpleDialog
          content={
            <Grid className={classes.minGridSize}>
              <PreviewVideoForProduct
                videoLink={productForm.videoLink}
                validURL={validUrl}
                setValidUrl={setValidUrl}
                setLoadingValidatorUrl={setLoadingValidatorUrl}
              />
              <CustomInput
                name="videoLink"
                label="Altere ou adicione abaixo o link do vídeo"
                value={productForm.videoLink || ''}
                error={!validUrl}
                onChange={handleChange}
                loading={loadingValidatorUrl}
              />
            </Grid>
          }
          dialogTitle="Vídeo do produto"
          handleClose={handleCloseDialogVideoProduct}
          openDialog={openDialogVideoProduct}
        />
      )}

      {openDialogSupplier && (
        <SimpleDialog
          content={
            <Grid className={classes.minGridSize}>
              <TableSuppliers suppliers={productForm.suppliers} setProductForm={setProductForm} />
            </Grid>
          }
          dialogTitle="Fornecedores ou fabricantes"
          handleClose={handleCloseDialogSupplier}
          openDialog={openDialogSupplier}
        />
      )}

      {openDialogImages && (
        <SimpleDialog
          actionButton={
            <label htmlFor="upload-images">
              <input
                id="upload-images"
                accept="image/*"
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleUploadImage}
              />
              <Tooltip title={<Typography>Enviar Imagens</Typography>}>
                <IconButton component="span">
                  <CloudUploadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </label>
          }
          content={
            <>
              <Grid container justifyContent="center" className={classes.minGridSize}>
                {productForm.images
                  ? productForm.images.map((image, index) => (
                      <Grid item key={image.src}>
                        <Box position="relative">
                          <Box position="absolute" top="1px" right="202px">
                            <Tooltip
                              title={<Typography align="center">Alterar a posição</Typography>}
                            >
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => changeImagePosition(index, 'left')}
                              >
                                <ArrowLeftIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box position="absolute" top="1px" right="101px">
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => handleDeleteImage(image)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                          <Box position="absolute" top="1px" right="1px">
                            <Tooltip
                              title={<Typography align="center">Alterar a posição</Typography>}
                            >
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => changeImagePosition(index, 'right')}
                              >
                                <ArrowRightIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <SimpleImage
                            src={image.src}
                            height={250}
                            width={250}
                            alt={productForm.name}
                            className={classes.images}
                          />
                        </Box>
                      </Grid>
                    ))
                  : null}
                {productForm.images && productForm.images.length === 0 ? (
                  <Grid item>
                    <Typography>Nenhuma imagem foi enviada</Typography>
                  </Grid>
                ) : null}
              </Grid>

              {openDialogConfirmDeleteImage && (
                <SimpleDialog
                  openDialog={openDialogConfirmDeleteImage}
                  handleClose={handleCloseDialogConfirmDeleteImage}
                  dialogTitle="Deseja deletar esta imagem?"
                  dialogText="Tem certeza que deseja deletar esta imagem? Esta ação não pode ser desfeita."
                  content={<SimpleBackdrop loading={loadingUploadImage} />}
                  cancelButtonText="Cancelar"
                  confirmButtonText="Confirmar"
                  handleCancelButton={handleCloseDialogConfirmDeleteImage}
                  handleConfirmButton={handleConfirmDeleteImage}
                />
              )}
              <SimpleBackdrop loading={loadingUploadImage} />
            </>
          }
          dialogTitle="Imagens"
          handleClose={handleCloseDialogImages}
          openDialog={openDialogImages}
        />
      )}

      {openDialogRelatedProducts && (
        <RelatedProductsModal
          alreadySelectedProducts={productForm.relatedProducts}
          handleCloseRelatedProductsModal={handleCloseDialogRelatedProducts}
          openRelatedProductsModal={openDialogRelatedProducts}
          handleConfirmSelect={handleChangeRelatedProducts}
        />
      )}
    </Grid>
  );
}

export default memo(InformationTab);
