import { Chip, IconButton, Link as LinkMaterial } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const useStyles = makeStyles({
  closeIcon: {
    color: '#ddd',
  },
});

function InvoiceInquiryLink({ invoice, showInvoiceNumber }) {
  const classes = useStyles();

  let nfeLink;

  if (invoice?.key) {
    nfeLink = `http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConteudo=7PhJ+gAVw2g=&tipoConsulta=completa&nfe=${invoice.key}`;
  }

  if (invoice?.key && (invoice.danfe || nfeLink)) {
    return (
      <LinkMaterial
        onClick={(event) => event.stopPropagation()}
        href={invoice.danfe || nfeLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {showInvoiceNumber && invoice.number ? (
          <Chip label={invoice.number} size="small" />
        ) : (
          <IconButton size="small">
            <img src="/assets/imgs/nfe-icon.svg" width={20} alt="Icone NFe" />
          </IconButton>
        )}
      </LinkMaterial>
    );
  }

  if (invoice?.key === '') {
    return <img src="/assets/imgs/nfe-icon.svg" width={20} alt="Icone NFe" />;
  }

  return <CloseIcon className={classes.closeIcon} />;
}

export default InvoiceInquiryLink;
